import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_EMP_POOL_STORAGE_GEN_COL_DEF, transferRowData } from "presentation/constant/EmptyPoolStorageGeneration/EmptyPoolStorageGenColumnDefinition";
import { useEmptyPoolStorageGenVM } from "presentation/hook/EmptyPoolStorageGeneration/useEmptyPoolStorageGenVM";
import { useEmptyPoolStorageGenTracked } from "presentation/store/EmptyPoolStorageGeneration/EmptyPoolStorageGenProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";


const EmptyPoolStorageGenTablePanel: React.FC = () => {
    const [emptyPoolStorageGenState] = useEmptyPoolStorageGenTracked();
    const emptyPoolStorageGenVM = useEmptyPoolStorageGenVM();
    let gridRef: any = useRef();
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);

    useEffect(() => {
        if (!emptyPoolStorageGenState.selectedEmptyPoolDatas ||
            emptyPoolStorageGenState.selectedEmptyPoolDatas.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(INITIAL_EMP_POOL_STORAGE_GEN_COL_DEF?.slice());

            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    useEffect(() => {
        if (onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();

    }, [onTableSelectionClicked])

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        emptyPoolStorageGenVM.updateSelectedEmpPoolDatas(selectedRows);
    }, [emptyPoolStorageGenVM])

    const memoEmptyPoolStorageDataTable = useMemo(() => {
        return (
            <>
                <NbisTable
                    id='empty-pool-storage-data-table'
                    isNewColumnSetting={true}
                    columns={INITIAL_EMP_POOL_STORAGE_GEN_COL_DEF?.slice()}
                    // headerLabel={EmptyPoolStorageGenConstant.Charge.DATA_LIST}
                    data={transferRowData(emptyPoolStorageGenState.emptyPoolDatas ?? [])}
                    showPaginator={false}
                    editable={false}
                    showHeaderIcons={true}
                    showAddIcon={false}
                    showDeleteButton={false}
                    showReloadIcon={false}
                    isScrollHighlighted={true}
                    selectionMode={false}
                    handleSelectionChanged={handleSelectionChange}
                    ref={gridRef}
                    rowSelection={"multiple"}
                    gridHeight="customHeight"
                    customHeight="calc(100vh - 125px)"
                />
            </>
        );
    }, [emptyPoolStorageGenState.emptyPoolDatas, handleSelectionChange])

    return <><TableWrapper>{memoEmptyPoolStorageDataTable}</TableWrapper></>;
}

export default memo(EmptyPoolStorageGenTablePanel);
